export const marketplaceLogos = new Map([
  ['capillary', '/static/img/logos/capillary.svg'],
  ['bbo', '/static/img/logos/bbo.svg'],
  ['zalora', '/static/img/logos/zalora.svg'],
  ['custom', '/static/img/logos/custom.svg'],
  ['kumu', '/static/img/logos/kumu.svg'],
  ['lazada', '/static/img/logos/lazada.svg'],
  ['shopee', '/static/img/logos/shopee.svg'],
  ['shopify', '/static/img/logos/shopify.svg'],
  ['tiktok', '/static/img/logos/tiktok.svg'],
  ['woocommerce', '/static/img/logos/woocommerce.svg'],
  ['amazon', '/static/img/logos/amazon.svg'],
  ['ebay', '/static/img/logos/ebay.svg'],
  ['tokopedia', '/static/img/logos/tokopedia.svg'],
]);
